(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@turf/bbox-polygon"), require("@turf/boolean-intersects"), require("@turf/boolean-within"), require("@turf/intersect"));
	else if(typeof define === 'function' && define.amd)
		define(["@turf/bbox-polygon", "@turf/boolean-intersects", "@turf/boolean-within", "@turf/intersect"], factory);
	else if(typeof exports === 'object')
		exports["cartoReactCore"] = factory(require("@turf/bbox-polygon"), require("@turf/boolean-intersects"), require("@turf/boolean-within"), require("@turf/intersect"));
	else
		root["cartoReactCore"] = factory(root["@turf/bbox-polygon"], root["@turf/boolean-intersects"], root["@turf/boolean-within"], root["@turf/intersect"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__855__, __WEBPACK_EXTERNAL_MODULE__97__, __WEBPACK_EXTERNAL_MODULE__55__, __WEBPACK_EXTERNAL_MODULE__956__) {
return 